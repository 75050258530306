import * as React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import logo from "../images/LogoColor.svg"
import {InstagramLogo, TwitterLogo} from "../components/svg.js"
import Head from "../components/head.js"


const Home = ({ data }) => {
    const imageData = data.background.childImageSharp.fluid
  
    return (
    <>
      <Head />
        
        <section className="hero">
          <BackgroundImage
            Tag="div"
            className={"hero-overlay"}
            fluid={imageData}
            style={{
              position: "absolute",
              backgroundPosition: "bottom"
            }}
          >
          <div className="hero-overlay"></div>
          </BackgroundImage>
          <div className="hero-overlay-gradient"></div>
          <div className="hero-content">
            <img src={logo} className="logo" alt="" />
            <hgroup>
              <h1>LEVELLER</h1>
              <h2>producing and distributing media<br />
                for a world yet to come<br />
                for a zero-hour workday<br />
                for the end of landlords, borders, and boredom<br />
                <Link to="/about/">&#10087;</Link><br />
              </h2>
            </hgroup>
          </div>
        </section>
        
        <section className="main">
            
          <section id="tools">
            <h1>tools</h1>
            <ul className="card-list">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <li key={node.id}>
                <Link to={node.fields.slug}><img src={node.frontmatter.imgthumb.childImageSharp.original.src} alt={node.frontmatter.imgalt} /></Link>
                <h2><Link to={node.fields.slug}>{node.frontmatter.title}</Link></h2>
              </li>
            ))}
            </ul>
          </section>
          
          <section id="find">
            <h1>find us</h1>
            <ul className="social">
              <li>
                <a href="https://instagram.com/LevellerComms" target="_blank" rel="noopener noreferrer">
                  <InstagramLogo />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/LevellerComms" target="_blank" rel="noopener noreferrer">
                  <TwitterLogo />
                </a>
              </li>
            </ul>
          </section>
          
          <section id="friends">
            <h1>projects we like</h1>
            <h3><a href="https://inhabit.global/" target="_blank" rel="noopener noreferrer">Inhabit</a></h3>
            <h3><a href="https://illwilleditions.com/" target="_blank" rel="noopener noreferrer">Ill Will Editions</a></h3>
            <h3><a href="https://lucyparsonslabs.com/" target="_blank" rel="noopener noreferrer">Lucy Parsons Labs</a></h3>
          </section>
        </section>
        
    </>
    )
}

export default Home

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            imgthumb {
              childImageSharp {
                original {
                  src
                }
              }
            }
            imgalt
          }
          fields {
            slug
          }
        }
      }
    }
    background: file(relativePath: {eq: "hero-banner-lores.jpg"}) {
      id
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`