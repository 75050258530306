import React from "react"

export function InstagramLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.6 26.6"
      height="26.6"
      width="26.6"
      id="Layer_1"
      version="1.1">


      <g
      transform="translate(0.05000019,-1.9562177e-4)"
      id="g866"><path
      d="M 7.798828,3.90625e-4 C 3.098828,3.90625e-4 0,3.1992196 0,7.7992196 V 18.79922 c 0,2.3 0.699218,4.301171 2.199219,5.701171 1.399999,1.400001 3.399609,2.09961 5.599609,2.09961 h 10.900391 c 2.199999,0 4.199609,-0.699609 5.599609,-2.09961 1.4,-1.4 2.201172,-3.401171 2.201172,-5.701171 V 7.7992196 c 0,-2.3 -0.69961,-4.19961 -2.09961,-5.59961 -1.4,-1.39999997 -3.301562,-2.199218975 -5.601562,-2.199218975 z M 7.699218,2.3988286 h 11 c 1.699999,0 3,0.5 4,1.5 0.899999,1 1.40039,2.300391 1.40039,3.900391 V 18.79922 c 0,1.6 -0.5,3.00039 -1.5,3.90039 -0.9,0.9 -2.300391,1.400391 -3.90039,1.400391 H 7.798828 c -1.6,0 -2.898438,-0.500391 -3.898438,-1.400391 -1,-1 -1.5,-2.4 -1.5,-4 V 7.6996096 c 0,-1.6 0.498438,-3.000391 1.398438,-3.90039 0.9,-0.9 2.30039,-1.400391 3.90039,-1.400391 z"
      id="path855" /><path
      d="m 20.298518,4.4999736 c -0.9,0 -1.59961,0.699609 -1.59961,1.599609 0,0.9 0.69961,1.599609 1.59961,1.599609 0.9,0 1.601562,-0.699609 1.601562,-1.599609 0,-0.9 -0.701562,-1.599609 -1.601562,-1.599609 z"
      id="path851" /><path
      d="m 13.199219,6.3988286 c -3.700001,0 -6.798829,3.000781 -6.798829,6.8007814 0,3.700001 2.998829,6.800781 6.798829,6.800781 3.8,0 6.90039,-3.00078 6.90039,-6.800781 0,-3.8000004 -3.200391,-6.8007814 -6.90039,-6.8007814 z m 0,2.400391 a 4.4000001,4.4000001 0 0 1 4.40039,4.4003904 4.4000001,4.4000001 0 0 1 -4.40039,4.400391 4.4000001,4.4000001 0 0 1 -4.400391,-4.400391 4.4000001,4.4000001 0 0 1 4.400391,-4.4003904 z"
      id="path849" /></g>
    </svg>
  )
}

export function TwitterLogo() {
  return (
    <svg
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 26.6 26.6"
       height="26.6"
       width="26.6"
       id="Layer_1"
       version="1.1">
    <path
       id="path2"
       d="m 18.397773,2.5312729 c -3.003429,0 -5.436556,2.4348788 -5.436556,5.4365569 0,0.4258071 0.04745,0.8420308 0.14032,1.2389251 C 8.5823749,8.9807092 4.5749091,6.8145471 1.8930254,3.5254938 1.4251633,4.3280438 1.1571992,5.2638503 1.1571992,6.2600287 c 0,1.8872193 0.9600101,3.5492818 2.4196698,4.5261853 -0.8919169,-0.02891 -1.7299919,-0.273659 -2.4624502,-0.681067 -8.763e-4,0.02365 -0.00175,0.04651 -0.00175,0.07016 0,2.631944 1.875364,4.831057 4.3636194,5.33046 -0.4564723,0.125289 -0.9381065,0.189946 -1.4340053,0.189946 -0.3504585,0 -0.6904164,-0.03271 -1.0215996,-0.09754 0.6921546,2.159701 2.6984612,3.733698 5.0771983,3.778381 -1.8600584,1.457907 -4.204653,2.325553 -6.7524864,2.325553 -0.43894925,0 -0.86958834,-0.02536 -1.29539648,-0.0753 2.40589788,1.542017 5.26364078,2.441915 8.33365728,2.441915 10.000333,0 15.469456,-8.284181 15.469456,-15.4694568 0,-0.2365594 -0.004,-0.4702582 -0.0154,-0.7033132 1.062766,-0.7666275 1.985088,-1.724118 2.71229,-2.8166734 -0.974275,0.4319411 -2.021668,0.7241902 -3.122983,0.8556121 1.12322,-0.6720042 1.984881,-1.7362073 2.39229,-3.0066194 -1.0505,0.6229399 -2.214377,1.076661 -3.453248,1.3193536 -0.991802,-1.0557529 -2.40441,-1.716354 -3.968331,-1.716354 z" />
    </svg>
  )
}